@font-face {
  font-family: "TTNorms";
  src: url("assets/TT-Norms-Fonts/TTNorms-Regular.otf") format("truetype");
 // font-weight: normal;
  // font-style: normal;
}

@font-face {
  font-family: "TTNorms-Bold";
  src: url("assets/TT-Norms-Fonts/TTNorms-Bold.otf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "TTNorms-Medium";
  src: url("assets/TT-Norms-Fonts/TTNorms-Medium.otf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "TTNorms-Light";
  src: url("assets/TT-Norms-Fonts/TTNorms-Light.otf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "TTNormsBold";
  src: url("assets/TT-Norms-Fonts/TTNorms-Bold.otf") format("truetype");
 // font-weight: bold;
  // font-style: normal;
}

@font-face {
  font-family: "TTNormsMedium";
  src: url("assets/TT-Norms-Fonts/TTNorms-Medium.otf") format("truetype");
  //font-weight: 500;
  // font-style: normal;
}


@font-face {
  font-family: "TTNormsLight";
  src: url('assets/TT-Norms-Fonts/TTNorms-Light.otf') format("truetype");
  font-weight: normal;
  font-style: normal;
}