/*
 * Application global variables.
 */

// Angular Material custom theme
// See https://material.angular.io/guide/theming for more details.
//
// You can also read https://medium.com/@tomastrajan/the-complete-guide-to-angular-material-themes-4d165a9d24d1
// for more insight about Angular Material theming.

@import "~@angular/material/theming";
//@import "variables.js";
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$app-primary: mat-palette($mat-blue);
// stylelint-disable-next-line value-keyword-case
$app-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

// Material design icons font path
$material-design-icons-font-directory-path: "~material-design-icons-iconfont/dist/fonts/";

$dark-green: #00af83;

$red: #db3145;

$light-green: #5cc272;

$drak-grey: #35354d;

$light-grey: #5e5e72;

$white: #ffffff;

$light-white: #e6e6e6;

$dim-white: #cac9cf;

$blue: #2e9ccc;

$bar-graph-green: #5cc272;

$page-bg-color: #24233f;

$header-bg-color: #100f21;

$font-color: #ffffff;

$medium-color: #ea8d3a;

$card-bg-color: #24233f;

$line: #191835;

$bg-color: #191835;

$indigo-blue: #100f21;

$pale-yellow: #ea8d3a;

$purple: #9b59b6;

$pale-blue: #75999b;

.theme-light {
  --page-bg-color: #f3f2f1;
  --status-bg-color: #fff;
  --card-bg-color: #ffffff;
  --mat-card-bg-color: #ffffff;
  --nomal-font-color: #000000;
  --page-header-color: #334867;
  --mat-select-panel: #ffffff;
  --field-border: #979593; //#EAEAEA;
  --graph-subtitle-color: #000000;
  --pop-title-color: #000000;
  --pop-content-color: #2c3e50;
  --pop-option-text-color: #2c3e50;
  --title-font-color: #2c3e50;
  --tab-filter-option-color: #000000;
  --login-card-color: #ffffff;
  --scrollbar-thumb-color: #7f8c8d;
  --lable-name-color: #000000;
  --border-line-color: #979593; //hr
  --mat-header-border: #979593;
  --date-picker-background-color: #ffffff;
  --no-results-color: #000000;
  --mat-select-border-color: #2c3e50;
  --cursor-pointer-color: #2c3e50;
  --qt-section-mask-color: #bdc3c7; //Mast of text section in Quarantine tracker
  --dashboard-cards-border: #2c3e50;
  --badge-text-color: #ffffff;
  --name-in-header: #f7f7f7;
  --desg-in-header: #bdc3c7;
  --primary-btn-color: #5556b5;
  --primary-icon-color: #5556b5;
  --primary-border-color: #5556b5;
  --secondary-text-color: #5556b5;
  --dialog-container-color: #ffffff;
  --dialog-font-color: #000000;
  --dialog-button-font-color: #ffffff;
  --red--color: #c4314b;
  --green--color: #00b260;
  --amber--color: #eba509;
  --purple--color: #954ab4;
  --mat-chip-bg-color: #eaeaea;
  --progress-bar-text: #979593;
  /*air quality theme variables*/
  --airquality-moderate: #ccad00;
  --airquality-good: #00b260;
  --airquality-unhealthyforsome: #e67d1f;
  --airquality-unhealthy: #c4314b;
  --airquality-veryunhealthy: #954ab4;
  --airquality-hazardous: #678f91;
  --action-divider-border: #979593;
  --disabled-input-control: #eaeaea;
  --selected-tab-rulesconfig: #eaeaea;
  --tr-separator-color: #eaeaea;
  --enable-disable-text: #5556b5;
  --total-alerts: #e67d1f;
  --low-alerts: #ccad00;
  --nb-tab-border: #edf1f7;
  --nb-border: #e4e9f2;
  --info-color: #f7f7f7;
  --item-background-color: #eaeaea;
  --logout-bg-color: #334867;
  --menu-expansion-background: #eaeaea;
}
.theme-dark {
  --page-bg-color: #191835;
  --status-bg-color: #191835;
  --card-bg-color: #24233f;
  --nomal-font-color: #ffffff;
  --mat-card-bg-color: #23233f;
  --page-header-color: #100f21;
  --mat-select-panel: #35354d;
  --field-border: #5e5e72; //#EAEAEA;
  --graph-subtitle-color: #cac9cf;
  --pop-title-color: #000000;
  --pop-content-color: #424242;
  --pop-option-text-color: #35354d;
  --title-font-color: #f8f8f8;
  --tab-filter-option-color: #ffffff;
  --login-card-color: #e6e6e6;
  --scrollbar-thumb-color: #5d5e72;
  --lable-name-color: #ffffff;
  --border-line-color: #5e5e72; //hr
  --mat-header-border: #5e5e72;
  --date-picker-background-color: #24233f;
  --no-results-color: #ffffff;
  --mat-select-border-color: #2e9ccc;
  --cursor-pointer-color: #2e9ccc;
  --qt-section-mask-color: #5e5e72; //Mast of text section in Quarantine tracker
  --dashboard-cards-border: #0fa7ea;
  --badge-text-color: #ffffff;
  --name-in-header: #ffffff;
  --desg-in-header: #a5a5a5;
  --primary-btn-color: #2e9ccc;
  --primary-icon-color: #2e9ccc;
  --primary-border-color: #2e9ccc;
  --secondary-text-color: #2e9ccc;
  --action-divider-border: #5e5e72;
  --disabled-input-control: #5e5e72;
  --selected-tab-rulesconfig: #5e5e70;
  --tr-separator-color: #5e5e72;
  --dialog-container-color: #ffffff;
  --dialog-font-color: #000000;
  --dialog-button-font-color: #ffffff;
  --red--color: #db3145;
  --green--color: #5cc272;
  --amber--color: #fccf5a;
  --purple--color: #9b59b6;
  --mat-chip-bg-color: #191835;
  --progress-bar-text: #5e5e72;
  /*air quality theme variables*/
  --airquality-moderate: #e3ab19;
  --airquality-good: #5cc272;
  --airquality-unhealthyforsome: #ea8d3a;
  --airquality-unhealthy: #db3145;
  --airquality-veryunhealthy: #9b59b6;
  --airquality-hazardous: #75999b;
  --enable-disable-text: #2e9ccc;
  --total-alerts: #ea8d3a;
  --low-alerts: #e3ab19;
  --nb-tab-border: #2f3d4e;
  --nb-border: #24233f;
  --info-color: #191835;
  --item-background-color: #191835;
  --logout-bg-color: #24233f;
  --menu-expansion-background: #191835;
}
