// html {
//   font-size: 16px;
// }

//Variables
// Colors

//mixins
//media query
@mixin screen($size) {
  $extrasmallD: "(min-width: 320px) and (max-width: 480px)";
  $smallD: "(min-width: 481px) and (max-width: 767px)";
  $mobile: "(max-width: 767px)";
  $mobileLandscape: "(max-width: 860px) and (orientation: landscape)";
  $mediumD: "(min-width: 768px) and (max-width: 1024px)";
  $mediumDLandscape: "(min-width: 768px) and (max-width: 1024px) and (orientation: landscape)";
  $largeD: "(min-width: 1025px) and (max-width: 1280px)";
  $extraLargeD: "(min-width: 1281px)";
  $ipadPro: "(min-width: 1024px) and (max-width: 1280px)";

  @if $size==extrasmallD {
    @media only screen and #{$extrasmallD} {
      @content;
    }
  } @else if $size==smallD {
    @media only screen and #{$smallD} {
      @content;
    }
  } @else if $size==mobile {
    @media only screen and #{$mobile} {
      @content;
    }
  } @else if $size==mobileLandscape {
    @media only screen and #{$mobileLandscape} {
      @content;
    }
  } @else if $size==mediumD {
    @media only screen and #{$mediumD} {
      @content;
    }
  } @else if $size==largeD {
    @media only screen and #{$largeD} {
      @content;
    }
  } @else if $size==extraLargeD {
    @media only screen and #{$extraLargeD} {
      @content;
    }
  } @else if $size==ipadPro {
    @media only screen and #{$ipadPro} {
      @content;
    }
  } @else {
    @media only screen and #{$size} {
      @content;
    }
  }
}
//usage
//@include screen(extraLargeD){
//    width: 100%;
//}

//centering content
@mixin center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

//Flex
@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-align-center {
  display: flex;
  align-items: center;
}

@mixin space-between-center {
  justify-content: space-between;
  align-items: center;
}

@mixin align-center {
  align-items: center;
  justify-content: center;
}

@mixin bulk-upload-style {
  .title {
    font-size: 16px;
  }
  .message {
    flex-direction: column;
    height: 100%;
    button {
      width: 100%;
      margin: 5px 0;
      img {
        display: none;
      }
    }
    .browse {
      width: 100%;
      margin: 0 0 13% 0;
    }
    .or {
      display: none;
    }
  }
  .button-container {
    display: block;
    width: 100%;
  }
  ::ng-deep .mat-button-wrapper {
    display: inline;
  }
}
